import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'accueil', loadChildren:()=>import('./accueil/accueil.module').then(m=>m.AccueilModule)},
  {path: 'apropos', loadChildren:()=>import('./apropos/apropos.module').then(m=>m.AproposModule)},
  {path: 'medicament', loadChildren:()=>import('./medicaments/medicaments.module').then(m=>m.MedicamentsModule)},
  {path: 'alimentation', loadChildren:()=>import('./alimentation/alimentation.module').then(m=>m.AlimentationModule)},
  {path: 'aliment', loadChildren:()=>import('./aliments/aliments.module').then(m=>m.AlimentsModule)},
  {path: 'blog', loadChildren:()=>import('./blog/blog.module').then(m=>m.BlogModule)},
  {path: 'contact', loadChildren: ()=>import('./contact/contact.module').then(m=>m.ContactModule)},
  {path: 'login', loadChildren:()=>import('./login/login.module').then(m=>m.LoginModule)},
  {path: 'inscription', loadChildren:()=>import('./inscription/inscription.module').then(m=>m.InscriptionModule)},
  {path: 'admin', loadChildren:()=>import('./admin/admin.module').then(m=>m.AdminModule)},
  {path: 'stock', loadChildren:()=>import('./boutique/boutique.module').then(m=>m.BoutiqueModule)},
  {path: 'rapport', loadChildren:()=>import('./rapport/rapport.module').then(m=>m.RapportModule)},
  {path:'devu', loadChildren:()=>import('./devu/devu.module').then(m=>m.DevuModule)},

  {path:'socomax', loadChildren:()=>import('./socomax/socomax.module').then(m=>m.SocomaxModule)},
  {path:'super',loadChildren:()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)},
  {path: 'stocks', loadChildren:()=>import('./rapport-stock/rapport-stock.module').then(m=>m.RapportStockModule)},
  {path:'bon-commande', loadChildren:()=>import('./bon-commande/bon-commande.module').then(m=>m.BonCommandeModule)},

  {path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
