<section class=" bg-gradient-to-r from-teal-600 to-rose-600 lg:py-16 py-8"  *ngIf="footerSite$ | async as footer">
  <p class=" text-lg lg:text-3xl font-extralight text-center text-white px-4 lg:px-36">{{footer.descriptiton_footer}}
  </p>
  <p class=" text-center px-4 lg:px-96 text-lg lg:text-2xl text-white py-8">{{footer.detail_footer}}</p>
</section>

<footer class="bg-gray-100 text-center">
  <div class="px-6 py-12">
    <form action="">
      <div class="grid md:grid-cols-3 gird-cols-1 gap-4 justify-center items-center">
        <div class="md:ml-auto md:mb-6">
          <p class="text-gray-800 text-xl">
            <strong>Inscrivez-vous à notre newsletter</strong>
          </p>
        </div>

        <div class="md:mb-6">
          <input
            type="text"
            class="
              form-control
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-rose-700 focus:outline-none
            "
            id="exampleFormControlInput1"
            placeholder="Email address"/>
        </div>

        <div class="md:mr-auto mb-6">
          <button type="button" class="inline-block px-6 py-2.5 bg-rose-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-rose-500 hover:shadow-lg focus:bg-rose-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-rose-700 active:shadow-lg transition duration-150 ease-in-out">Subscribe</button>
        </div>
      </div>
    </form>
  </div>

  <div class="text-center text-white p-4 text-xl bg-rose-700 ">
    © 2022 Copyright
  </div>
</footer>



