<nav class="bg-blue-900" >
    <!-- container -->
    <div class="container px-4 flex flex-wrap py-2 justify-between mx-auto lg:space-x-4" >
        <div>
          <a href="#" class=" inline-flex p-2 text-white text-2xl font-bold uppercase -tracking-wider">
            <span *ngIf="user.profil_id==1">Elicom Group</span> 
            <span *ngIf="user.profil_id!=1">{{user.denomination | uppercase}}</span></a>
          <button class="lg:hidden inline-flex items-center justify-center text-white border h-10 w-10 ml-auto rounded-md outline-none focus:outline-none" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
          </button>
        </div>
        <!--menu-->
        <div class="w-full lg:inline-flex lg:w-auto mt-2 lg:mt-0 py-2 space-x-4" >
          <div class="group inline-block">
            <button *ngIf="droits.profil_id==2" routerLink="/admin/dashboad" class="outline-none focus:outline-none  flex items-center min-w-32" >
              <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">TABLEAU DE BORD</span>
            </button>
            <button *ngIf="droits.profil_id==1" routerLink="/super/super-admin" class="outline-none focus:outline-none  flex items-center min-w-32" >
              <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">TABLEAU DE BORD</span>
            </button>
            <button *ngIf="droits.profil_id==4" routerLink="/socomax/dashboard" class="outline-none focus:outline-none  flex items-center min-w-32" >
              <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">TABLEAU DE BORD</span>
            </button>
          </div>
            <div class="group inline-block">
                <button  class="outline-none focus:outline-none  flex items-center min-w-32" >
                  <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">IHM</span>
                </button>
                <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                  <li routerLink="/admin/magasin" *ngIf="user.profil_id==1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Sociétés </li>
                  <li routerLink="/admin/banque" *ngIf="droits.banque" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Banques</li>
                  <li routerLink="/admin/caisse_recette" *ngIf="droits.caisse_recette" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses recettes</li>
                  <li routerLink="/admin/caisse-depense/list" *ngIf="droits.caisse_depense" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses depenses</li>
                  <li routerLink="/admin/fournisseur" *ngIf="droits.fournisseur" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Fournisseurs</li>

                  <li routerLink="/bon-commande" *ngIf="droits.fournisseur" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Bon de commande</li>

                  <li routerLink="/admin/clients" *ngIf="droits.client" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Clients</li>

                  <li routerLink="/stock/articles" *ngIf="droits.article " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Articles</li>
                  <li routerLink="/stock/stocks" *ngIf="droits.stock" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Stock</li>

                  <li routerLink="/socomax/ventes" *ngIf="user.societe_id==3 && droits.ventes " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Ventes</li>
                  <li routerLink="/stock/fiche-sortie" *ngIf="droits.fiche_stock_sortie" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Fiche de sotie du stock</li>

                  <li routerLink="/admin/proformas/add-proforma-sans-devu" *ngIf="droits.proforma_express" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures proformas avec dévu express</li>
                  <li routerLink="/admin/proformas/add-proforma-avec-devu" *ngIf="droits.proforma_avec_devu " routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures profoma avec dévu</li>
                  <li routerLink="/admin/proformas/list" *ngIf="droits.proforma" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures profoma en attente de confirmation</li>
                  <li routerLink="/admin/proformas/proforma-confirmer" *ngIf="droits.proforma_confirme " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures proforma confirmer</li>
                  <li routerLink="/admin/proformas/proforma-attente-paiement" *ngIf="droits.proforma_attente_paiement" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures proformas en attente de paiement</li>

                  <li routerLink="/socomax/invoices" *ngIf="droits.facturation && user.societe_id==3 " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures</li>

                  <li routerLink="/admin/factures/liste" *ngIf="droits.facturation" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures</li>

                  <li routerLink="/socomax/dettes-invoice" *ngIf="droits.facture_non_payer && user.societe_id==3" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>

                  <li routerLink="/admin/factures/dette" *ngIf="droits.facture_non_payer " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>


                  <li routerLink="/socomax/bon-commande" *ngIf="droits.bon_de_commande && user.societe_id==3 " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures confirmer par le bon de commande</li>

                  <li routerLink="/admin/bon-commande-list" *ngIf="droits.bon_de_commande  " routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures confirmer par le bon de commande</li>

                  <li routerLink="/devu/liste" *ngIf="droits.rapport_proformat_confirmer" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Liste des devus</li>
                </ul>
            </div>
            <div class="group inline-block">
                <button class="outline-none focus:outline-none  flex items-center min-w-32" >
                  <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">RAPPORTS</span>
                </button>
                <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                  <li routerLink="/rapport/proforma_enregistre" *ngIf="droits.rapport_proformat_enregistrer " routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures profoma en attente de confirmation</li>
                  <li routerLink="/rapport/proforma_confirmer" *ngIf="droits.rapport_proformat_confirmer " routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black ">Factures proforma confirmer</li>


                  <li routerLink="/rapport/facture_non_payees" *ngIf="droits.rapport_facture_non_payer " routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>
                  <li routerLink="/rapport/facture_payer" *ngIf="droits.rapport_facture_payer " routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures payer</li>

                  <li routerLink="/socomax/rapport/facture-non-payer" *ngIf="droits.rapport_facture_non_payer  && user.societe_id==3" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>
                  <li routerLink="/socomax/rapport/facture-payer" *ngIf="droits.rapport_facture_payer  && user.societe_id==3" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures payer</li>



                  <li routerLink="/rapport/banques" *ngIf="droits.rapport_banque" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Banques</li>
                  <li routerLink="/rapport/caisse_recette" *ngIf="droits.rapport_caisse_recette" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses recettes</li>
                  <li routerLink="/rapport/caisse_depense" *ngIf="droits.rapport_caisse_depense" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses dépenses</li>
                  <li routerLink="/rapport/fournisseur" *ngIf="droits.rapport_fournisseur" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Fournisseurs</li>
                  <li routerLink="/stocks/rapport"  routerLinkActive="active" *ngIf="droits.rappot_stock"   class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Rapport des stocks</li>
                </ul>
            </div>
            <div class="group inline-block">
              <button class="outline-none focus:outline-none  flex items-center min-w-32" >
                <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">ADMINISTRATION</span>
              </button>
              <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                <li routerLink="/admin/profil" *ngIf="droits.profil" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Profils et droits </li>
                <li routerLink="/admin/password" routerLinkActive="active" *ngIf="droits.mot_de_passe" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Modifier le mot de passe</li>
                <li routerLink="/admin/user" *ngIf="droits.user" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Utilisateurs</li>
              </ul>
          </div>
        </div>
        <div class="w-full lg:inline-flex lg:w-auto mt-2 lg:mt-0 py-2 space-x-4">
          <button  (click)="confirmBox()" class="outline-none focus:outline-none  flex items-center min-w-32 space-x-2 "  >
            <span class="flex  text-lg font-medium text-gray-300  rounded-md py-2">Déconnexion</span> <img src="{{base_url}}{{user.image}}" alt="" class="h-8 w-8 rounded-full"> <span class="text-white uppercase">{{user.name}} {{user.postnom}}</span>
          </button>
        </div>
    </div>
</nav>



